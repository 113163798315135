.rights {
    /*margin-top: 25px;*/
    /*margin: 25px auto;*/
    position: fixed;
    /*bottom: 20px;*/
    bottom: 0;
    left: 0;
    /*color: #ff0000;*/
    /*margin-bottom: 18px;*/
    width: 100%;
    height: 36px;
}

.rights .flex-layout {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.rights .flex-layout .copy-right {

}

.rights .flex-layout .bei-an {

}